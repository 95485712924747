import React from 'react';
import { withFormik, Form, getIn } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import * as Yup from 'yup';

import UserForm from './UserForm';

const ProfileForm = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    action,
    setFieldValue,
    fromShow,
    user,
    values
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

  return (
    <Form>
      <Row className="mt-3 mb-4">
        <UserForm
          action={action}
          user={user}
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          touched={touched}
          fromShow={fromShow}
        />
      </Row>

      {!fromShow && (
        <Row className="d-flex mb-4">
          <Col
            className="mt-n3 mt-md-0 mb-4"
            xs={{ span: 6, offset: 0 }}
            md={{ span: 2, offset: 2 }}
          >
            <Link to="/change_password">Cambiar contraseña</Link>
          </Col>
          <Col
            xs={{ span: 2, offset: 0 }}
            md={{ span: 4, offset: 2 }}
            style={{ textAlign: 'right' }}
          >
            <Button
              type="submit"
              className="block"
              variant={submitVariant}
              onClick={onHide}
            >
              {btnMessage}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

const setInitialValues = ({ user, reorderProfile }) => {
  const {
    id,
    avatarInfo,
    email,
    fullName,
    address,
    birthdate,
    fatherOrMother,
    dateOfBirthFirstSon,
    dateOfBirthSecondSon,
    dateOfBirthThirdSon,
    documentIdentity,
    whatsappNumber
  } = user;

  return {
    user: {
      id,
      avatar: getIn(avatarInfo, 'fileSignature'),
      email,
      fullName,
      address,
      documentIdentity,
      birthdate: birthdate ? moment(birthdate).toDate() : '',
      fatherOrMother,
      reorderProfile,
      whatsappNumber,
      dateOfBirthFirstSon: dateOfBirthFirstSon
        ? moment(dateOfBirthFirstSon).toDate()
        : '',
      dateOfBirthSecondSon: dateOfBirthSecondSon
        ? moment(dateOfBirthSecondSon).toDate()
        : '',
      dateOfBirthThirdSon: dateOfBirthThirdSon
        ? moment(dateOfBirthThirdSon).toDate()
        : ''
    }
  };
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    fullName: Yup.string().nullable(),
    whatsappNumber: Yup.number().when('reorderProfile', {
      is: reorderProfile => reorderProfile === 1,
      then: Yup.number().required('Debes ingresar tu número de WhatsApp')
    })
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProfileForm);
