import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import NotificationDataTable from './NotificationDataTable';
import { indexSlidesRequest } from '../../requests/slides';
import { Slides } from '../../components';

const ChallengeIndex = () => {
  const dispatch = useDispatch();
  const [slides, setSlides] = useState([]);

  const handleIndexSlidesRequest = () => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 10
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
      }
    });
  };
  useEffect(() => {
    handleIndexSlidesRequest();
  }, []);
  return (
    <>
      <h2 className="screen-title">Notificaciones</h2>
      <Slides items={slides} />
      <div id="nn-notifications-wrapper">
        <NotificationDataTable />
      </div>
    </>
  );
};

export default withRouter(connect()(ChallengeIndex));
