import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { indexNewsRequest, markNewsAsReadRequest } from '../../requests/news';
import { emptyUnreadNews } from '../../actions/utils';
import NewsList from './NewsList';
import { Slides, Loader } from '../../components';
import { indexSlidesRequest } from '../../requests/slides';

const NewsIndex = () => {
  const [onRequest, setOnRequest] = useState(true);
  const [news, setNews] = useState([]);
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();

  const {
    settings: { menuType, newsTitle }
  } = useSelector(state => state.utils);

  const handleIndexSlidesRequest = () => {
    setOnRequest(true);
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 3
      },
      successCallback: result => {
        setOnRequest(false);
        setSlides(camelCaseRecursive(result.data.data));
      }
    });
  };

  const handleIndexNewsRequest = () => {
    setOnRequest(true);
    indexNewsRequest({
      dispatch,
      params: {
        sort_direction: 'desc',
        sort_column: 'publication_date',
        news_type: 0
      },
      successCallback: result => {
        setOnRequest(false);
        setNews(camelCaseRecursive(result.data.data));
      }
    });
  };

  useEffect(() => {
    handleIndexSlidesRequest();
    handleIndexNewsRequest();
    markNewsAsReadRequest({
      dispatch
    });
    dispatch(emptyUnreadNews());
  }, [dispatch]);

  return onRequest ? (
    <Loader />
  ) : (
    <>
      <Slides items={slides} />
      <h2 className="screen-title">{newsTitle || 'Noticias'}</h2>
      <div className={menuType === 1 ? 'margin-card-products' : ''}>
        <NewsList newsList={news} newsType />
      </div>
    </>
  );
};

export default withRouter(connect()(NewsIndex));
