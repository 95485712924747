import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Loader } from '../../components';
import { indexBenefitsRequest } from '../../requests/benefits';
import BenefitCard from './BenefitCard';

const BenefitIndex = () => {
  const [onRequest, setOnRequest] = useState(true);
  const [benefits, setBenefits] = useState([]);
  const location = useLocation();
  const [categoryId, categoryName, categoryLede] = location.state;
  const dispatch = useDispatch();

  const handleIndexBenefitsRequest = () => {
    setOnRequest(true);
    indexBenefitsRequest({
      dispatch,
      params: {
        filter_by_category: categoryId,
        order_by_position: 'asc'
      },
      successCallback: result => {
        setOnRequest(false);
        setBenefits(camelCaseRecursive(result.data.data));
      }
    });
  };

  useEffect(() => {
    handleIndexBenefitsRequest();
  }, [dispatch, categoryId]);

  return onRequest ? (
    <Loader />
  ) : (
    <>
      <div className="d-flex mb-3">
        <h1 className="screen-title category-name">{categoryName}</h1>
      </div>
      <h5 className="ml-1 mb-3 mt-n2">{categoryLede}</h5>
      <div className="content-benefits">
        {benefits.map(category => (
          <div key={category.id} className="container-benefit-card">
            <BenefitCard categoryId={categoryId} item={category} />
          </div>
        ))}
      </div>
    </>
  );
};

export default BenefitIndex;
