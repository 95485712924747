import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { indexCategoryBenefitsRequest } from '../../requests/categoryBenefits';
import { Slides, Loader } from '../../components';
import { indexSlidesRequest } from '../../requests/slides';
import BenefitCard from './BenefitCard';

const CategoryBenefitIndex = () => {
  const [onRequest, setOnRequest] = useState(true);
  const [categoryBenefits, setCategoryBenefits] = useState([]);
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();

  const {
    settings: { benefitNameParam }
  } = useSelector(state => state.utils);

  const handleIndexSlidesRequest = () => {
    setOnRequest(true);
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 7
      },
      successCallback: result => {
        setOnRequest(false);
        setSlides(camelCaseRecursive(result.data.data));
      }
    });
  };

  const handleIndexCategoryRequest = () => {
    setOnRequest(true);
    indexCategoryBenefitsRequest({
      dispatch,
      params: {
        order_by_position: 'asc'
      },
      successCallback: result => {
        setOnRequest(false);
        setCategoryBenefits(camelCaseRecursive(result.data.data));
      }
    });
  };

  useEffect(() => {
    handleIndexSlidesRequest();
    handleIndexCategoryRequest();
  }, [dispatch]);

  return onRequest ? (
    <Loader />
  ) : (
    <>
      {slides.length > 0 && <Slides items={slides} />}
      <h1 className="screen-title">{benefitNameParam || 'Beneficios'}</h1>
      <Row>
        {categoryBenefits.map(category => (
          <Col md={4} key={category.id}>
            <BenefitCard category item={category} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default withRouter(connect()(CategoryBenefitIndex));
