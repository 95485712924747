const primary = '#1b3478';
const secondary = '#3b63cf';
const white = '#ffffff';

const baseColors = {
  primary,
  secondary,
  red: '#dc3545',
  primaryBg: white,

  // datatable
  bgDatatable: white,
  colorDatatable: 'null',

  // select
  bgSelect: white,
  bgMultiValueSelect: secondary,
  hoverBgMultiValueRemoveSelect: secondary,
  colorMultiValueSelect: primary,
  hoverTextMultiValueRemoveSelect: white,

  // style logo login
  styleLoginLogo: {
    maxWidth: 280,
    minWidth: 265,
    maxHeight: 300
  },
  textColorLogin: 'text-white',
  classNameBtnLogin: 'btn-red',

  // text points product
  colorTextPointsProducts: primary,

  gray100: '#f8f9fa',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
  gray900: '#282828'
};

export default baseColors;
